<template>
    <div class="ticket">
        <div class="ticket-logo" :style="{backgroundImage: `url('${$parent.logoURL}')`}" @click="$router.push(base + '/')"></div>
        <ModalSatisfaction v-if="modalShow"></ModalSatisfaction>
        <Steps :steps="processSteps" :active="3" />
        <div v-if="nbrProducts < 1" class="noProducts" >{{$t('errorEmptyTicket')}}</div>
        <div v-else class="ticket-wrapper">
            <h4 class="success-text">{{brandName.toUpperCase()}} {{storeName}}</h4>
            <span>{{$t('keywordText')}}:</span>
            <h3 class="keyword keyword-ticket">{{ticket.keyword}}</h3>
            
            <div class="qr-code-img-wrapper">
                <img :src="qrCodeImg"/>
            </div>
            <div class="ticket-amount">
                <strong v-if="nbrProducts>0">{{formatPrice(ticket.totalAmount.toFixed(2))}} / {{nbrProducts}} Art.</strong>
                <strong v-else>{{formatPrice(0.00)}} / {{nbrProducts}} Art.</strong>
            </div>
            <div class="divider"></div>
            <div class="clock">{{formattedTime}}</div>
            <div class="ticket-products">
                <ProductBloc v-for="cartProduct in sortedCartProducts" :key="cartProduct.id" :product="cartProduct.product" :forcedQty="cartProduct.qty" withBorder hideQty/>
            </div>
        </div>
        <div class="ticket-footer">
            <span :class="{'send-ticket-btn': true, 'disabled': !basketIsValidated}" @click="showSendModal">{{$t("receiveReceipt")}}</span>
            <b-modal centered
                size="sm"
                header-text-variant="light"
                no-close-on-backdrop
                no-close-on-esc
                hide-header
                hide-footer
                style="padding: 0;"
                v-model="showMailModal">

                <div class="food-custom-modal ">
                    <div class="food-custom-modal-header sms-mail-selector-wrapper">
                        <span :class="{'selector': true, 'active': selectedSendMethod == 'mail'}" @click="selectedSendMethod = 'mail'">{{$t("receiptModalMailLabel")}}</span>
                        <span :class="{'selector': true, 'active': selectedSendMethod == 'sms'}" @click="selectedSendMethod = 'sms'">{{$t("receiptModalSMSLabel")}}</span>
                    </div>
                    <template v-if="selectedSendMethod == 'mail'">
                        <span class="food-custom-modal-text">{{$t("receiptModalMailText")}}</span>
                        <input type="email" class="custom-input" v-model="email">
                    </template>
                    <template v-else-if="selectedSendMethod == 'sms'">
                        <span class="food-custom-modal-text">{{$t("receiptModalSMSText")}}</span>
                        <vue-phone-number-input class="my-2" :default-country-code="tel.countryCode || 'FR'" :preferred-countries="['FR', 'BE', 'DE', 'ES', 'PT', 'US', 'GB', 'CH']" v-model="phoneNumber" @update="tel = $event" :no-example="false"/>

                    </template>
                    <div class="delete-modal-actions">
                        <button class="btn nok" @click="showMailModal = false">{{$t("close")}}</button>
                        <button class="btn ok" @click="sendTicket">{{$t("send")}}</button>
                    </div>
                </div>

            </b-modal>
        </div>
    </div>
</template>

<script>
    import {isNotNull, sendMails, sendSMS} from "@/assets/js/Utils"
    import ModalSatisfaction from "@/components/Common/ModalSatisfaction";
    import Steps from "@/components/Food/Steps";
    import ProductBloc from "@/components/Food/ProductBloc";
    var qrcodeGenerator = require('qrcode-generator')
    var Utils = require("@/assets/js/Utils");
    var Basket = require("@/assets/js/Food/Basket");
    var Customer = require("@/assets/js/Customer");
    var Stats = require("@/assets/js/Stats");
    var MQTT = require("@/assets/js/Food/MqttService");

    export default {
        name: 'Ticket',
        components: {ModalSatisfaction, Steps, ProductBloc},
        props: {
            'paymentSuccess' : String
        },
        data(){
            return{
                customerBasket: null,
                img_url : null,
                ticket : {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                qrCodeImg :'',
                fromRoute : null,
                modalShow: false,
                time: "",
                clockParams: {
                    month: "long",
                    weekday: "long",
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                },
                keyword: sessionStorage.getItem("DKC_food_keyword"),
                processSteps: Utils.getSteps(this.$t),
                storeName: localStorage.getItem("DKC_food_city") || "Seclin",
                brandName: localStorage.getItem("DKC_food_brand") || this.$t("yourStore"),
                showMailModal: false,
                email: localStorage.getItem("DKC_customerEmail") || "",
                phoneNumber: "",
                selectedSendMethod: 'mail',
                basketIsValidated: false,
                base: localStorage.getItem("DKC_base"),
                tel: JSON.parse(localStorage.getItem("DKC_customerPhoneNumber")) || ""
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
        })
        },
        created(){
            if(this.tel != ""){
                this.phoneNumber = this.tel.phoneNumber
            }
            this.$parent.checkDemonstrationLogo();
            document.addEventListener("demoFetchedEvent", e =>{
                this.storeName = localStorage.getItem("DKC_food_city") || "Seclin"
                this.brandName = localStorage.getItem("DKC_food_brand") || this.$t("yourStore")
            }, false)
            let lang = localStorage.getItem("DKC_lang") || this.$route.query.lang || "en"
            this.time = (new Date()).toLocaleString(lang, this.clockParams)
            setInterval(() => {this.time = (new Date()).toLocaleString(lang, this.clockParams)}, 999);
            var config = Utils.getConfig();
            this.img_url = config.url_img;
            var qr = qrcodeGenerator(0, 'L');

            if(isNotNull(this.$route.query.ticketId)){
                var qrData = this.$route.query.ticketId;
                var demoId = this.$route.query.demoId;
                var univers = this.$route.query.univers;
                this.$i18n.locale = lang;
                let self = this;
                Basket.getTicket(this.$route.query.ticketId, function (ticket) {
                    self.ticket = ticket;
                    self.ticket.products = ticket.basketProducts;
                    qrData += "__" + ticket.customer.firstName;
                    qr.addData(qrData);
                    qr.make();
                    self.qrCodeImg = qr.createDataURL(10, 0);

                    var parameters = {
                        complexIdCustomer: ticket.customer.complexId,
                        complexIdDemonstration: demoId,
                        typeId: univers,
                        nbTicketAccessFromMail: 1
                    }
                    if(!self.$route.query.inDKS){
                        Stats.setStats(parameters);
                    }
                })
            }else{
                var firstname = localStorage.getItem("DKC_firstname");
                var self = this;
                setInterval(() => {this.time = (new Date()).toLocaleString(lang, this.clockParams)}, 999);
                let customerBasket = this.$parent.basket;
                if(customerBasket.products.length > 0){
                    Basket.validBasket(function(res) {
                        localStorage.setItem("DKC_basket", JSON.stringify(res));
                        self.$parent.basketChange();
                        let mqttMessage = {
                            "encodedTicketId": localStorage.getItem("DKC_encodedTicketId"),
                            "clientFirstname": localStorage.getItem("DKC_firstname"),
                            "clientLastname": localStorage.getItem("DKC_lastname"),
                            "customerComplexId": localStorage.getItem("DKC_complexId"),
                            "lang": self.$i18n.locale
                        }
                        MQTT.publishMessage("PAYMENT_DONE", JSON.stringify(mqttMessage));

                        var loyaltyUse = localStorage.getItem("DKC_loyaltyToUse");
                        var ticketAmount = localStorage.getItem("DKC_ticketAmount");
                        if(isNotNull(loyaltyUse) && isNotNull(ticketAmount) && isNotNull(self.$parent.customer) && isNotNull(self.$parent.customer.loyalty)){
                            var newNbPoints = self.$parent.customer.loyalty.loyaltyPoint - loyaltyUse;
                            var winPoints = (ticketAmount * Utils.getConfig().loyaltyPourcent) / 100
                            newNbPoints += winPoints;
                            newNbPoints = newNbPoints.toFixed(2);
                            if(newNbPoints > 0){
                                Customer.updateCustomerLoyaltyPoints(newNbPoints);
                            }else{
                                Customer.updateCustomerLoyaltyPoints(0);
                            }
                            localStorage.removeItem("DKC_loyaltyToUse");
                        }

                        let encodedTicketId = localStorage.getItem("DKC_encodedTicketId");
                        if(isNotNull(encodedTicketId) && isNotNull(firstname)){
                            qr.addData(encodedTicketId + "__" + firstname);
                            qr.make();
                            self.qrCodeImg = qr.createDataURL(10, 0);
                        }
                        self.basketIsValidated = true
                    });
                }else{
                    let encodedTicketId = localStorage.getItem("DKC_encodedTicketId");
                    if(isNotNull(encodedTicketId) && isNotNull(firstname)){
                        qr.addData(encodedTicketId + "__" + firstname);
                        qr.make();
                        this.qrCodeImg = qr.createDataURL(10, 0);
                    }
                }
                this.ticket = JSON.parse(localStorage.getItem("DKC_ticket"));
                Stats.setStats({nbTicketAccess: 1});

                if(!Utils.getLocalSatisfaction("FOOD")){
                    this.modalShow = true;
                }
            }
        },
        methods:{
            handleBack (fallback) {
                if (!this.fromRoute) {
                    this.$router.push(this.base + fallback);
                } else {
                    window.location.replace(window.location.origin + this.base + this.fromRoute.path);
                }

            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            redirect(){
                window.location.replace(window.location.origin + this.base + '/');
            },
            getLang(){
                return this.$root.$i18n.locale;
            },
            showSendModal(){
                if(this.basketIsValidated){
                    this.showMailModal = true;
                }
            },
            sendTicket(){
                if(this.basketIsValidated){
                    let encodedTicketId = localStorage.getItem("DKC_encodedTicketId");
                    const ticketUrl = window.location.origin + this.base + "/ticket?ticketId=" + encodedTicketId + "&demoId=" + localStorage.getItem("DKC_demoId") + "&univers=" + localStorage.getItem("DKC_univers")+ "&lang=" + localStorage.getItem("DKC_lang");
                    if(this.email != ""){
                        Customer.updateCustomerEmail(this.email, (error) => {
                            if(!error){
                                localStorage.setItem("DKC_customerEmail", this.email)
                                sendMails(ticketUrl);
                            }
                        });
                    }
                    if(this.tel.isValid){
                        localStorage.setItem("DKC_customerPhoneNumber", JSON.stringify(this.tel))
                        sendSMS(this.$t("ticketSms") + "https://awl.li/food" + `?${this.$route.params.p}&` +  "ticketId=" + encodedTicketId, this.tel.formattedNumber.replace("+", "00"))
                    }
                }
                this.showMailModal = false
            }
        },
        computed:{
            nbrProducts : function() {
                var count = 0;
                if(this.ticket.products){
                    this.ticket.products.forEach(function(cartProduct) {
                        count+=cartProduct.qty;
                    });
                    return count
                }
            },
            sortedCartProducts(){
                return this.ticket.products.sort((p1, p2) => p2.product.price - p1.product.price)
            },
            formattedTime(){
                let time = this.time.split(" ")
                let result = ""
                return time.map(elmt => {return elmt.charAt(0).toUpperCase() + elmt.slice(1)})
                    .join(" ")
            }
        }
    }
</script>